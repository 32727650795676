import network from 'network';
import React from 'react';
import ReactDOM from 'react-dom';
import Root from './Root';

import 'assets/scss/material-kit-pro-react.scss?v=1.9.0';
import './styles/index.scss';
import './i18n';

if (network) {
  ReactDOM.render(<Root />, document.getElementById('root'));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// import * as serviceWorker from './serviceWorker';
// serviceWorker.unregister();

console.log("Are you an experienced developer? We're hiring");
console.log('vaporwave is defi and defi is vaporwave.');

console.log(
  '\r\n_/\\/\\____/\\/\\_____________________________________________________________________________________________________\r\n_/\\/\\____/\\/\\__/\\/\\/\\______/\\/\\/\\/\\______/\\/\\/\\____/\\/\\__/\\/\\__/\\/\\______/\\/\\__/\\/\\/\\______/\\/\\__/\\/\\____/\\/\\/\\___\r\n_/\\/\\____/\\/\\______/\\/\\____/\\/\\__/\\/\\__/\\/\\__/\\/\\__/\\/\\/\\/\\____/\\/\\__/\\__/\\/\\______/\\/\\____/\\/\\__/\\/\\__/\\/\\/\\/\\/\\_\r\n___/\\/\\/\\/\\____/\\/\\/\\/\\____/\\/\\/\\/\\____/\\/\\__/\\/\\__/\\/\\________/\\/\\/\\/\\/\\/\\/\\__/\\/\\/\\/\\______/\\/\\/\\____/\\/\\_______\r\n_____/\\/\\______/\\/\\/\\/\\/\\__/\\/\\__________/\\/\\/\\____/\\/\\__________/\\/\\__/\\/\\____/\\/\\/\\/\\/\\______/\\________/\\/\\/\\/\\_\r\n___________________________/\\/\\___________________________________________________________________________________\r\n'
);

// https://www.freeformatter.com/javascript-escape.html#ad-output
// https://patorjk.com/software/taag/#p=display&f=Ticks&t=Vaporwave
