'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
exports.arbitrum = void 0;
var beefyfinance_1 = require('./platforms/beefyfinance');
var sushi_1 = require('./platforms/sushi');
var tokens_1 = require('./tokens/tokens');
var convertSymbolTokenMapToAddressTokenMap_1 = require('../../util/convertSymbolTokenMapToAddressTokenMap');
var _arbitrum = {
  platforms: {
    beefyfinance: beefyfinance_1.beefyfinance,
    sushi: sushi_1.sushi,
  },
  tokens: tokens_1.tokens,
  tokenAddressMap: convertSymbolTokenMapToAddressTokenMap_1.convertSymbolTokenMapToAddressTokenMap(
    tokens_1.tokens
  ),
};
exports.arbitrum = _arbitrum;
