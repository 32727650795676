'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
exports.heco = void 0;
var beefyfinance_1 = require('./platforms/beefyfinance');
var mdex_1 = require('./platforms/mdex');
var tokens_1 = require('./tokens/tokens');
var convertSymbolTokenMapToAddressTokenMap_1 = require('../../util/convertSymbolTokenMapToAddressTokenMap');
var _heco = {
  platforms: {
    beefyfinance: beefyfinance_1.beefyfinance,
    mdex: mdex_1.mdex,
  },
  tokens: tokens_1.tokens,
  tokenAddressMap: convertSymbolTokenMapToAddressTokenMap_1.convertSymbolTokenMapToAddressTokenMap(
    tokens_1.tokens
  ),
};
exports.heco = _heco;
