'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
exports.aurora = void 0;
var beefyfinance_1 = require('./platforms/beefyfinance');
var trisolaris_1 = require('./platforms/trisolaris');
var tokens_1 = require('./tokens/tokens');
var convertSymbolTokenMapToAddressTokenMap_1 = require('../../util/convertSymbolTokenMapToAddressTokenMap');
var _aurora = {
  platforms: {
    beefyfinance: beefyfinance_1.beefyfinance,
    trisolaris: trisolaris_1.trisolaris,
  },
  tokens: tokens_1.tokens,
  tokenAddressMap: convertSymbolTokenMapToAddressTokenMap_1.convertSymbolTokenMapToAddressTokenMap(
    tokens_1.tokens
  ),
};
exports.aurora = _aurora;
