'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
exports.beefyfinance = void 0;
var cowllector = '0xd529b1894491a0a26B18939274ae8ede93E81dbA';
exports.beefyfinance = {
  strategyOwner: '0x6d28afD25a1FBC5409B1BeFFf6AEfEEe2902D89F',
  vaultOwner: '0x2e8B7aba218759C07aA6Ae051FC386D411cF99d3',
  keeper: '0x10aee6B5594942433e7Fc2783598c979B030eF3D',
  rewarder: cowllector,
  treasurer: '0x3Eb7fB70C03eC4AEEC97C6C6C1B59B014600b7F7',
  launchpoolOwner: cowllector,
  rewardPool: '0x5B96bbAca98D777cb736dd89A519015315E00D02',
  treasury: '0xaDB9DDFA24E326dC9d337561f6c7ba2a6Ecec697',
  beefyFeeRecipient: '0x070c12844A9eB215276DbE178a92cF86157DDBaA',
  multicall: '0xBa5041B1c06e8c9cFb5dDB4b82BdC52E41EA5FC5',
  bifiMaxiStrategy: '0x5135C0af3080DF01ABF66491d5a1eD21fBEF3a7C',
};
