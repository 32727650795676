'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
exports.beefyfinance = void 0;
var cowllector = '0x78535BA8cAACa07f0D16077De8dc46BDF063FF02';
var zeroAddress = '0x0000000000000000000000000000000000000000';
var treasury = '0x6159B3D96CE5F7dCC07FeB17C4A76385159f92Fc';
exports.beefyfinance = {
  devMultisig: zeroAddress,
  treasuryMultisig: zeroAddress,
  strategyOwner: '0x6d62ED9470Eb0fcfe2C17493ac32B555be44E2Cd',
  vaultOwner: '0x6d62ED9470Eb0fcfe2C17493ac32B555be44E2Cd',
  keeper: '0x6d62ED9470Eb0fcfe2C17493ac32B555be44E2Cd',
  rewarder: cowllector,
  treasurer: '0x6159B3D96CE5F7dCC07FeB17C4A76385159f92Fc',
  launchpoolOwner: cowllector,
  rewardPool: '0x6C1FF667535344a3e9e6cAFB8b400e664B310Fa3',
  treasury: treasury,
  beefyFeeRecipient: '0x16C80c7aDE7B94Bc992fe004b4817Ef95D2a5e85',
  multicall: '0x1198f78efd67DFc917510aaA07d49545f4B24f11',
  bifiMaxiStrategy: '0x7B6049808Bb7Da3CD6F7F13fCdbff6846803aA79',
};
