export const auroraZaps = [
  // {
  //   // SpookySwap
  //   zapAddress: '0x8afc0f9BdC5DcA9f0408Df03A03520bFa98A15aF',
  //   ammRouter: '0xF491e7B69E4244ad4002BC14e878a34207E38c29',
  //   ammFactory: '0x152eE697f2E276fA89E96742e9bB9aB1F2E61bE3',
  //   ammPairInitHash: '0xcdf2deca40a0bd56de8e3ce5c7df6727e5b1bf2ac96f283fa9c4b3e6b42ea9d2',
  // },
  // {
  //   // JetSwap
  //   zapAddress: '0x63934e5aba120f2bae2418711ef0f20f7db26cd2',
  //   ammRouter: '0x845E76A8691423fbc4ECb8Dd77556Cb61c09eE25',
  //   ammFactory: '0xf6488205957f0b4497053d6422F49e27944eE3Dd',
  //   ammPairInitHash: '0xa5e6089ea250dac750e4867fc4ce7f2a864bd94446564351fe9329f378963974',
  // },
  // {
  //   // SpiritSwap
  //   zapAddress: '0x5618c4C0A9c024e77eb9A5b4424f492f94C86F14',
  //   ammRouter: '0x16327E3FbDaCA3bcF7E38F5Af2599D2DDc33aE52',
  //   ammFactory: '0xEF45d134b73241eDa7703fa787148D9C9F4950b0',
  //   ammPairInitHash: '0xe242e798f6cee26a9cb0bbf24653bf066e5356ffeac160907fe2cc108e238617',
  // },
];
