'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
exports.ChainId = void 0;
var ChainId;
(function (ChainId) {
  ChainId[(ChainId['aurora'] = 1313161554)] = 'aurora';
  ChainId[(ChainId['bsc'] = 56)] = 'bsc';
  ChainId[(ChainId['heco'] = 128)] = 'heco';
  ChainId[(ChainId['polygon'] = 137)] = 'polygon';
  ChainId[(ChainId['fantom'] = 250)] = 'fantom';
  ChainId[(ChainId['avax'] = 43114)] = 'avax';
  ChainId[(ChainId['one'] = 1666600000)] = 'one';
  ChainId[(ChainId['arbitrum'] = 42161)] = 'arbitrum';
  ChainId[(ChainId['celo'] = 42220)] = 'celo';
  ChainId[(ChainId['moonriver'] = 1285)] = 'moonriver';
  ChainId[(ChainId['cronos'] = 25)] = 'cronos';
})((ChainId = exports.ChainId || (exports.ChainId = {})));
