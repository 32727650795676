'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
exports.tokens = void 0;
var MATIC = {
  name: 'Wrapped Matic',
  address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
  symbol: 'WMATIC',
  decimals: 18,
  chainId: 137,
  website: 'https://polygon.technology/',
  description:
    'Polygon is a protocol and a framework for building and connecting Ethereum-compatible blockchain networks. Aggregating scalable solutions on Ethereum supporting a multi-chain Ethereum ecosystem.',
  logoURI:
    'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0/logo.png',
};
var MAI = {
  name: 'Mai',
  address: '0xa3Fa99A148fA48D14Ed51d610c367C61876997F1',
  symbol: 'MAI',
  decimals: 18,
  chainId: 137,
  website: 'https://www.mai.finance/',
  description:
    "MAI is a stable coin collateralized by your MATIC holdings. It's powered by Qi Dao, a protocol that enables any cryptocurrency community to create stablecoins backed by their native tokens.",
  logoURI: 'https://raw.githubusercontent.com/0xlaozi/qidao/main/images/mimatic-red.png',
};
var _tokens = {
  EROWAN: {
    name: 'SifChain EROWAN',
    symbol: 'EROWAN',
    address: '0xa7051C5a22d963b81D71C2BA64D46a877fBc1821',
    chainId: 137,
    decimals: 18,
    website: 'https://sifchain.finance/',
    description:
      'Sifchain aims to be the first Omni-Chain DEX, targeting 20–25 blockchains for cross-chain integration.',
    logoURI: 'https://assets.coingecko.com/coins/images/14044/small/EROWAN.png?1614656300',
  },
  DPI: {
    name: 'Defipulse Index',
    symbol: 'DPI',
    address: '0x85955046DF4668e1DD369D2DE9f3AEB98DD2A369',
    chainId: 137,
    decimals: 18,
    website: 'https://defipulse.com/',
    description:
      'The DeFi Pulse Index is a capitalization-weighted index that tracks the performance of decentralized financial assets across the market.',
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x1494CA1F11D487c2bBe4543E90080AeBa4BA3C2b/logo.png',
  },
  SHIB: {
    name: 'SHIBA INU',
    symbol: 'SHIB',
    address: '0x6f8a06447Ff6FcF75d803135a7de15CE88C1d4ec',
    chainId: 137,
    decimals: 18,
    website: 'https://shibatoken.com/',
    description: 'A Decentralized Meme Token that Evolved into a Vibrant Ecosystem.',
    logoURI: 'https://assets.coingecko.com/coins/images/11939/small/shiba.png?1622619446',
  },
  FTM: {
    name: 'Fantom Token',
    symbol: 'FTM  ',
    address: '0xB85517b87BF64942adf3A0B9E4c71E4Bc5Caa4e5',
    chainId: 137,
    decimals: 18,
    website: 'https://fantom.foundation/',
    description:
      'Fantom is a fast, high-throughput open-source smart contract platform for digital assets and dApps.',
    logoURI: 'https://ftmscan.com/token/images/wFtm_32.png',
  },
  jEUR: {
    name: 'Jarvis Synthetic Euro',
    symbol: 'jEUR',
    address: '0x4e3Decbb3645551B8A19f0eA1678079FCB33fB4c',
    chainId: 137,
    decimals: 18,
    website: 'https://jarvis.network/',
    description:
      'jEUR is a multi-collateralized synthetic token tracking the price of Euro. It is built on the top of UMA and Chainlink.',
    logoURI: 'https://i.imgur.com/ZvP634G.png',
  },
  jGBP: {
    name: 'Jarvis Synthetic British Pound',
    symbol: 'jGBP',
    address: '0x767058F11800FBA6A682E73A6e79ec5eB74Fac8c',
    chainId: 137,
    decimals: 18,
    website: 'https://jarvis.network/',
    description:
      'jGBP is a multi-collateralized synthetic token tracking the price of the British Pound. It is built on the top of UMA and Chainlink.',
    logoURI: 'https://i.imgur.com/zOLD6Vf.png',
  },
  jCHF: {
    name: 'Jarvis Synthetic Swiss Franc',
    symbol: 'jCHF',
    address: '0xbD1463F02f61676d53fd183C2B19282BFF93D099',
    chainId: 137,
    decimals: 18,
    website: 'https://jarvis.network/',
    description:
      'jCHF is a multi-collateralized synthetic token tracking the price of the Swiss Franc. It is built on the top of UMA and Chainlink.',
    logoURI: 'https://i.imgur.com/EknGhAl.png',
  },
  JRT: {
    name: 'Jarvis Reward Token',
    symbol: 'JRT',
    address: '0x596eBE76e2DB4470966ea395B0d063aC6197A8C5',
    chainId: 137,
    decimals: 18,
    website: 'https://jarvis.network/',
    description:
      'The Jarvis Reward Token  is a utility token for securing and governing the Jarvis network, and rewarding agents who would bring value to it.',
    logoURI: 'https://cdn.coinranking.com/vLU4_a_Zp/jarvis.svg?size=48x48',
  },
  AUR: {
    name: 'AUREUS',
    symbol: 'AUR',
    address: '0xfAdE2934b8E7685070149034384fB7863860D86e',
    chainId: 137,
    decimals: 18,
    website: 'https://jarvis.network/',
    description:
      'AUR is the reward token for staking jFIAT LPs on KyberDMM and can be sold immediately or redeemed at the end of the fair launch for a share of the reserve pool.',
    logoURI: 'https://i.imgur.com/PyipL43.png',
  },
  pSING: {
    name: 'Sing Token',
    symbol: 'SING',
    address: '0xCB898b0eFb084Df14dd8E018dA37B4d0f06aB26D',
    chainId: 137,
    decimals: 18,
    website: 'https://singular.farm/',
    description:
      'Singular is a multichain, decentralized, strategic yield farm running on Polygon, BSC, Fantom and Okchain. Users are incentivized with a triple farming system.',
    logoURI: 'https://github.com/singularfarm/assets/blob/main/400.png?raw=true',
  },
  pBREW: {
    name: 'pBREW',
    symbol: 'pBREW',
    address: '0xb5106A3277718eCaD2F20aB6b86Ce0Fee7A21F09',
    chainId: 137,
    decimals: 18,
    website: 'https://polygon.cafeswap.finance/',
    description: 'Safest & Lowest fee AMM on BSC & Polygon.',
    logoURI: 'https://polygon.cafeswap.finance/images/tokens/pbrew.png',
  },
  FOX: {
    name: 'FOX',
    symbol: 'FOX',
    address: '0x65A05DB8322701724c197AF82C9CaE41195B0aA8',
    chainId: 137,
    decimals: 18,
    website: 'https://shapeshift.com/',
    description:
      'FOX is ShapeShift’s official loyalty token. Holders of FOX enjoy zero-commission trading and win ongoing USDC crypto payments from Rainfall (payments increase in proportion to your FOX holdings). Use at ShapeShift.com.',
    logoURI: 'https://assets.coingecko.com/coins/images/9988/large/FOX.png',
  },
  CRYSTL: {
    name: 'CrystalToken',
    symbol: 'CRYSTL',
    address: '0x76bF0C28e604CC3fE9967c83b3C3F31c213cfE64',
    chainId: 137,
    decimals: 18,
    website: 'https://www.crystl.finance/',
    description:
      'Crystl Finance is a decentralized yield farm that runs on Polygon and ApeSwap Polygon Exchange, and pays out $CRYSTL, the native currency. With it, you can earn profits from your capital in a way that is fair, transparent, and secure.',
    logoURI: 'https://www.crystl.finance/images/crystlbg.png',
  },
  TETU: {
    name: 'TETU',
    symbol: 'TETU',
    address: '0x255707B70BF90aa112006E1b07B9AeA6De021424',
    chainId: 137,
    decimals: 18,
    website: 'https://app.tetu.io/',
    description:
      'Tetu implements automated yield farming strategies in order to provide investors with a safe and secure method of receiving high yield on their investments.',
    logoURI: 'https://github.com/tetu-io/tetu-brand-assets/blob/master/token_icons/flat_icon.svg',
  },
  ALPHA: {
    name: 'ALPHA',
    symbol: 'ALPHA',
    address: '0x0B048D6e01a6b9002C291060bF2179938fd8264c',
    chainId: 137,
    decimals: 18,
    website: 'https://polyalpha.finance',
    description: 'A next generation decentralized stable-yield aggregating farm on Polygon.',
    logoURI: 'https://polyalpha.finance/images/pools/alpha.png',
  },
  DELIRIUM: {
    name: 'DELIRIUM',
    symbol: 'DELIRIUM',
    address: '0x238779aFfE6FFD475cB7e84582FcA7789F310Dc8',
    chainId: 137,
    decimals: 18,
    website: 'https://app.delirium.sandman.finance/farms',
    description: 'The first Polygon Community Owned AMM',
    logoURI: 'https://app.delirium.sandman.finance/images/farms/delirium.png',
  },
  WUSD: {
    name: 'Wault USD',
    symbol: 'WUSD',
    address: '0xb8ab048D6744a276b2772dC81e406a4b769A5c3D',
    chainId: 137,
    decimals: 18,
    website: 'https://wault.finance/',
    description:
      "WUSD is Wault Finance's commerce-backed stablecoin! The world's first commerce-backed stablecoin!",
    logoURI: 'https://app.wault.finance/polygon/assets/images/stablecoin/wusd.svg',
  },
  WATCH: {
    name: 'YieldWatch',
    symbol: 'WATCH',
    address: '0x09211Dc67f9fe98Fb7bBB91Be0ef05f4a12FA2b2',
    decimals: 18,
    chainId: 137,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8621.png',
    website: 'https://www.yieldwatch.net/',
    description: 'Smart Defi Dashboard',
  },
  AXS: {
    name: 'Axie Infinity Shard',
    symbol: 'AXS',
    address: '0x61BDD9C7d4dF4Bf47A4508c0c8245505F2Af5b7b',
    decimals: 18,
    chainId: 137,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/200x200/6783.png',
    website: 'https://axieinfinity.com/',
    description:
      'Axie Infinity is a Pokémon-inspired digital pet universe built on the Ethereum blockchain where anyone can earn token rewards through skilled gameplay and contributions to the ecosystem.',
  },
  POOL: {
    name: 'Pool Together',
    symbol: 'POOL',
    address: '0x25788a1a171ec66Da6502f9975a15B609fF54CF6',
    decimals: 18,
    chainId: 137,
    logoURI: 'https://assets.coingecko.com/coins/images/14003/small/PoolTogether.png?1613585632',
    website: 'https://pooltogether.com/',
    description:
      'Pool Together is an open source and decentralized protocol for no-loss prize games',
  },
  AVAX: {
    name: 'Avalanche',
    symbol: 'AVAX',
    address: '0x2C89bbc92BD86F8075d1DEcc58C7F4E0107f286b',
    decimals: 18,
    chainId: 137,
    logoURI: 'https://assets.coingecko.com/coins/images/12559/small/coin-round-red.png?1604021818',
    website: 'https://www.avax.network/',
    description:
      'Avalanche is the fastest smart contracts platform in the blockchain industry, as measured by time-to-finality, and has the most validators securing its activity of any proof-of-stake protocol.',
  },
  HT: {
    name: 'Huobi Token',
    symbol: 'HT',
    address: '0xA731349fa468614c1698fc46ebf06Da6F380239e',
    decimals: 18,
    chainId: 137,
    logoURI: 'https://assets.coingecko.com/coins/images/2822/small/huobi-token-logo.png?1547036992',
    website: 'https://www.hecochain.com/en-us/',
    description:
      'HECO is a decentralized and cost efficient public chain that Ethereum developers can easily get started with and smart contracts are seamlessly compatible.',
  },
  PEAR: {
    name: 'PearZap',
    symbol: 'PEAR',
    address: '0xc8bcb58caEf1bE972C0B638B1dD8B0748Fdc8A44',
    decimals: 18,
    chainId: 137,
    logoURI: 'https://assets.coingecko.com/coins/images/17173/small/pear200.png',
    website: 'https://pearzap.com/',
    description:
      'PearZap is a high yield farm & pool on the Polygon Chain (MATIC) and Binance Smart Chain',
  },
  SPADE: {
    name: 'PolygonFarm',
    symbol: 'SPADE',
    address: '0xf5EA626334037a2cf0155D49eA6462fDdC6Eff19',
    decimals: 18,
    chainId: 137,
    logoURI: 'https://polygonfarm.finance/images/spade.png',
    website: 'https://polygonfarm.finance/',
    description:
      'PolygonFarm Finance is a community led next generation stable-yield farming protocol established exclusively on the Polygon ecosystem',
  },
  $DG: {
    name: 'decentral.games',
    address: '0x2a93172c8DCCbfBC60a39d56183B7279a2F647b4',
    symbol: '$DG',
    decimals: 18,
    chainId: 137,
    logoURI:
      'https://raw.githubusercontent.com/sameepsi/quickswap-default-token-list/master/assets/dg.jpg',
    website: 'https://decentral.games/',
    description: 'decentral.games is a DAO-governed metaverse casino powered by $DG',
  },
  '0xBTC': {
    name: '0xBitcoin Token',
    address: '0x71B821aa52a49F32EEd535fCA6Eb5aa130085978',
    symbol: '0xBTC',
    decimals: 8,
    chainId: 137,
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xB6eD7644C69416d67B522e20bC294A9a9B405B31/logo.png',
    website: 'https://0xbitcoin.org/',
    description: 'Pure-mined neutral token.',
  },
  AAVE: {
    name: 'Aave',
    address: '0xD6DF932A45C0f255f85145f286eA0b292B21C90B',
    symbol: 'AAVE',
    decimals: 18,
    chainId: 137,
    website: 'https://aave.com/',
    description:
      'Aave is a decentralized non-custodial liquidity protocol where users can participate as depositors or borrowers. Depositors provide liquidity to the market to earn a passive income, while borrowers are able to borrow in an overcollateralized (perpetually) or undercollateralized (one-block liquidity) fashion.',
    logoURI: 'https://etherscan.io/token/images/aave_32.png',
  },
  AGA: {
    name: 'AGA Token',
    address: '0x033d942A6b495C4071083f4CDe1f17e986FE856c',
    symbol: 'AGA',
    decimals: 4,
    chainId: 137,
    logoURI: 'https://i.imgur.com/R0aQlym.png',
  },
  AGAr: {
    name: 'AGA Rewards',
    address: '0xF84BD51eab957c2e7B7D646A3427C5A50848281D',
    symbol: 'AGAr',
    decimals: 8,
    chainId: 137,
    logoURI: 'https://i.imgur.com/06BkcTT.png',
  },
  ANY: {
    name: 'Anyswap',
    address: '0x6aB6d61428fde76768D7b45D8BFeec19c6eF91A8',
    symbol: 'ANY',
    decimals: 18,
    chainId: 137,
    website: 'https://anyswap.exchange/bridge',
    description:
      'Anyswap is a fully decentralized cross chain swap protocol, based on Fusion DCRM technology, with automated pricing and liquidity system. Anyswap is a decentralized application running on the Fusion , Binance Smart Chain , Ethereum and Fantom blockchains. The first application from Anyswap is a DEX (Decentralized Exchange), which is called anyswap.exchange. ',
    logoURI: 'https://raw.githubusercontent.com/anyswap/Brand-assets/master/logo/c-128-white.svg',
  },
  ARIA20: {
    name: 'ARIANEE',
    address: '0x46F48FbdedAa6F5500993BEDE9539ef85F4BeE8e',
    symbol: 'ARIA20',
    decimals: 18,
    chainId: 137,
    logoURI: 'https://aria.fyi/images/Aria_Logo_256.png',
  },
  AZUKI: {
    name: 'DokiDokiAzuki',
    address: '0x7CdC0421469398e0F3aA8890693d86c840Ac8931',
    symbol: 'AZUKI',
    decimals: 18,
    chainId: 137,
    website: 'https://dokidoki.com/',
    description: 'AZUKI is a secondary token designed for use in Doki Doki NFT products',
    logoURI:
      'https://raw.githubusercontent.com/sameepsi/quickswap-default-token-list/master/assets/azuki.png',
  },
  BANANA: {
    name: 'ApeSwapFinance Banana',
    address: '0x5d47bAbA0d66083C52009271faF3F50DCc01023C',
    symbol: 'BANANA',
    decimals: 18,
    chainId: 137,
    website: 'https://apeswap.finance/',
    description:
      'ApeSwap is a leading decentralized exchange (DEX) on Binance Smart Chain and Polygon focused on offering a premier trading experience. Users are incentivized to pool liquidity on ApeSwap through yield farming to earn the native currency, $BANANA. Additionally, apes can use their earned $BANANA to stake and earn other tokens and unlock exclusive features. Built by DeFi apes, for DeFi apes, we have a dedicated team with years of experience who are committed to the DeFi community and growing the ApeSwap Jungle.',
    logoURI:
      'https://raw.githubusercontent.com/ApeSwapFinance/apeswap-token-lists/main/assets/BANANA.svg',
  },
  BIFI: {
    name: 'beefy.finance',
    address: '0xFbdd194376de19a88118e84E279b977f165d01b8',
    symbol: 'BIFI',
    decimals: 18,
    chainId: 137,
    website: 'https://www.beefy.finance/',
    description:
      'Beefy Finance is a Decentralized, Multi-Chain Yield Optimizer platform that allows its users to earn compound interest on their crypto holdings.',
    logoURI:
      'https://raw.githubusercontent.com/beefyfinance/beefy-app/prod/src/images/single-assets/BIFI.png',
  },
  BTU: {
    name: 'BTU Protocol',
    address: '0xFdc26CDA2d2440d0E83CD1DeE8E8bE48405806DC',
    symbol: 'BTU',
    decimals: 18,
    chainId: 137,
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xb683D83a532e2Cb7DFa5275eED3698436371cc9f/logo.png',
  },
  CC10: {
    name: 'Cryptocurrency Top Tokens Index',
    address: '0x9c49BA0212Bb5Db371e66b59D1565b7c06E4894e',
    symbol: 'CC10',
    decimals: 18,
    chainId: 137,
    website: 'https://indexed.finance/',
    description:
      'An index combining several popular medium/large-cap protocols, primarily drawn from decentralized finance',
    logoURI: 'https://etherscan.io/token/images/indexed-cc10_32.png',
  },
  CEL: {
    name: 'Celsius',
    address: '0xD85d1e945766Fea5Eda9103F918Bd915FbCa63E6',
    symbol: 'CEL',
    decimals: 4,
    chainId: 137,
    website: 'https://celsius.network/',
    description:
      'Meet Celsius. The first and only platform that earns you up to 17% yield on your crypto, rewards you every week and lets you borrow cash at the lowest rates. On Web, iOS, and Android.',
    logoURI: 'https://assets.coingecko.com/coins/images/3263/small/CEL_logo.png?1609598753',
  },
  CFI: {
    name: 'CyberFi Token',
    address: '0xeCf8f2FA183b1C4d2A269BF98A54fCe86C812d3e',
    symbol: 'CFI',
    decimals: 18,
    chainId: 137,
    logoURI: 'https://i.imgur.com/Z8V1O7H.png',
  },
  COMP: {
    name: 'Compound',
    address: '0x8505b9d2254A7Ae468c0E9dd10Ccea3A837aef5c',
    symbol: 'COMP',
    decimals: 18,
    chainId: 137,
    website: 'https://compound.finance/',
    description:
      'Compound is an algorithmic, autonomous interest rate protocol built for developers, to unlock a universe of open financial applications.',
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xc00e94Cb662C3520282E6f5717214004A7f26888/logo.png',
  },
  CTSI: {
    name: 'Cartesi Token',
    address: '0x2727Ab1c2D22170ABc9b595177B2D5C6E1Ab7B7B',
    symbol: 'CTSI',
    decimals: 18,
    chainId: 137,
    logoURI: 'https://i.imgur.com/q3SnElh.png',
  },
  DAI: {
    name: 'Dai Stablecoin',
    address: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
    symbol: 'DAI',
    decimals: 18,
    chainId: 137,
    website: 'https://makerdao.com/',
    description:
      'Multi-Collateral Dai, brings a lot of new and exciting features, such as support for new CDP collateral types and Dai Savings Rate.',
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x6B175474E89094C44Da98b954EedeAC495271d0F/logo.png',
  },
  DB: {
    name: 'Dark.Build',
    address: '0x0e59D50adD2d90f5111aca875baE0a72D95B4762',
    symbol: 'DB',
    decimals: 18,
    chainId: 137,
    logoURI: 'https://dark-build.app/logo192.png',
  },
  DEFI5: {
    name: 'DEFI Top 5 Tokens Index',
    address: '0x42435F467D33e5C4146a4E8893976ef12BBCE762',
    symbol: 'DEFI5',
    decimals: 18,
    chainId: 137,
    website: 'https://indexed.finance/',
    description:
      'A hyper-focused index of the most successful large-cap decentralized finance protocols across the Ethereum chain',
    logoURI: 'https://i.imgur.com/uVGtugL.png',
  },
  DEGEN: {
    name: 'DEGEN Index',
    address: '0x8a2870fb69A90000D6439b7aDfB01d4bA383A415',
    symbol: 'DEGEN',
    decimals: 18,
    chainId: 137,
    website: 'https://indexed.finance/',
    description:
      'A higher risk/reward index of promising Ethereum protocols that have significant room to grow',
    logoURI:
      'https://raw.githubusercontent.com/sameepsi/quickswap-default-token-list/master/assets/DEGEN_LOGO.png',
  },
  DMT: {
    name: 'Dark Matter Token',
    address: '0xd28449BB9bB659725aCcAd52947677ccE3719fD7',
    symbol: 'DMT',
    decimals: 18,
    chainId: 137,
    logoURI: 'https://darkmatter.finance/i/favicon/512x512.png',
  },
  DRC: {
    name: 'Digital Reserve Currency',
    address: '0xFeD16c746CB5BFeD009730f9E3e6A673006105c7',
    symbol: 'DRC',
    decimals: 0,
    chainId: 137,
    logoURI: 'https://pbs.twimg.com/profile_images/1318783238291292160/R4DxXdRA_400x400.jpg',
  },
  DSLA: {
    name: 'DSLA',
    address: '0xa0E390e9ceA0D0e8cd40048ced9fA9EA10D71639',
    symbol: 'DSLA',
    decimals: 18,
    chainId: 137,
    logoURI: 'https://storage.googleapis.com/stacktical-public/dsla.png',
  },
  ELET: {
    name: 'Elementeum',
    address: '0x07738Eb4ce8932CA961c815Cb12C9d4ab5Bd0Da4',
    symbol: 'ELET',
    decimals: 18,
    chainId: 137,
    logoURI: 'https://etherlegends.com/ELET.png',
  },
  EMON: {
    name: 'EthermonToken',
    address: '0xd6A5aB46ead26f49b03bBB1F9EB1Ad5c1767974a',
    symbol: 'EMON',
    decimals: 18,
    chainId: 137,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9651.png',
  },
  ETH: {
    name: 'Ether',
    address: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    symbol: 'ETH',
    decimals: 18,
    chainId: 137,
    website: 'https://ethereum.org/',
    description:
      'The native currency that flows within the Ethereum economy is called Ether (ETH). Ether is typically used to pay for transaction fees called Gas, and it is the base currency of the network.',
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
  },
  FISH: {
    name: 'Fish',
    address: '0x3a3Df212b7AA91Aa0402B9035b098891d276572B',
    symbol: 'FISH',
    decimals: 18,
    chainId: 137,
    website: 'https://polycat.finance/vaults',
    description:
      'Polycat is a value-oriented, economically sustainable and decentralized hybrid yield optimizer (yield farm and yield aggregator) running on the Polygon blockchain (formerly known as MATIC).',
    logoURI: 'https://i.imgur.com/ncleoTN.png',
  },
  FRAX: {
    name: 'Frax',
    address: '0x104592a158490a9228070E0A8e5343B499e125D0',
    symbol: 'FRAX',
    decimals: 18,
    chainId: 137,
    website: 'https://frax.finance/',
    description:
      'The Frax Protocol introduced the world to the concept of a cryptocurrency being partially backed by collateral and partially stabilized algorithmically.',
    logoURI: 'https://avatars.githubusercontent.com/u/56005256?s=200&v=4',
  },
  FSN: {
    name: 'Fusion',
    address: '0x2bF9b864cdc97b08B6D79ad4663e71B8aB65c45c',
    symbol: 'FSN',
    decimals: 18,
    chainId: 137,
    logoURI: 'https://cryptologos.cc/logos/fusion-fsn-logo.png?v=010',
  },
  FXS: {
    name: 'Frax Share',
    address: '0x3e121107F6F22DA4911079845a470757aF4e1A1b',
    symbol: 'FXS',
    decimals: 18,
    chainId: 137,
    website: 'https://frax.finance/',
    description:
      'The Frax Share token (FXS) is the non-stable, utility token in the protocol. It is meant to be volatile and hold rights to governance and all utility of the system. It is important to note that we take a highly governance-minimized approach to designing trustless money in the same ethos as Bitcoin. We eschew DAO-like active management such as MakerDAO. The less parameters for a community to be able to actively manage, the less there is to disagree on. Parameters that are up for governance through FXS include adding/adjusting collateral pools, adjusting various fees (like minting or redeeming), and refreshing the rate of the collateral ratio. No other actions such as active management of collateral or addition of human-modifiable parameters are possible other than a hardfork that would require voluntarily moving to a new implementation entirely. ',
    logoURI: 'https://app.sushi.com/images/tokens/fxs-square.jpg',
  },
  GAME: {
    name: 'GAME Credits',
    address: '0x8d1566569d5b695d44a9a234540f68D393cDC40D',
    symbol: 'GAME',
    decimals: 18,
    chainId: 137,
    logoURI: 'https://i.imgur.com/IIUglm9.png?1',
  },
  GUARD: {
    name: 'Helmet.insure',
    address: '0x948d2a81086A075b3130BAc19e4c6DEe1D2E3fE8',
    symbol: 'GUARD',
    decimals: 18,
    chainId: 137,
    website: 'https://www.guard.insure/insurance/',
    description:
      'Crypto-assets trading insurance platform on Polygon. Helmet allows users to create an insurance policy for any crypto asset in the market, protecting DeFi users against the risk of price fluctuations.',
    logoURI:
      'https://raw.githubusercontent.com/app-helmet-insure/guard-frontend/bb4e07794e41dce0e62ccc7342625ecc4afab247/src/assets/images/gurad-icon.svg',
  },
  GFARM2: {
    name: 'Gains V2',
    address: '0x7075cAB6bCCA06613e2d071bd918D1a0241379E2',
    symbol: 'GFARM2',
    decimals: 18,
    chainId: 137,
    logoURI: 'https://gains.farm/images/logo256.png',
  },
  GHST: {
    name: 'Aavegotchi GHST Token',
    address: '0x385Eeac5cB85A38A9a07A70c73e0a3271CfB54A7',
    symbol: 'GHST',
    decimals: 18,
    chainId: 137,
    website: 'https://www.aavegotchi.com/',
    description:
      'Aavegotchis are crypto-collectibles living on the Ethereum blockchain, backed by the ERC721 standard used in popular blockchain games.',
    logoURI: 'https://aavegotchi.com/images/ghsttoken.svg',
  },
  HEX: {
    name: 'HEXX',
    address: '0x23D29D30e35C5e8D321e1dc9A8a61BFD846D4C5C',
    symbol: 'HEX',
    decimals: 8,
    chainId: 137,
    logoURI: 'https://hex.com/favicon.png',
  },
  HH: {
    name: 'Holyheld',
    address: '0x521CddC0CBa84F14c69C1E99249F781AA73Ee0BC',
    symbol: 'HH',
    decimals: 18,
    chainId: 137,
    logoURI: 'https://assets.coingecko.com/coins/images/13719/small/hh.png?1611137626',
  },
  iFARM: {
    name: 'iFARM',
    address: '0xab0b2ddB9C7e440fAc8E140A89c0dbCBf2d7Bbff',
    symbol: 'iFARM',
    decimals: 18,
    chainId: 137,
    logoURI: 'https://raw.githubusercontent.com/harvestfi/assets/main/farm-logo.png',
  },
  IGG: {
    name: 'IG Gold',
    address: '0xe6FC6C7CB6d2c31b359A49A33eF08aB87F4dE7CE',
    symbol: 'IGG',
    decimals: 6,
    chainId: 137,
    logoURI: 'https://assets.coingecko.com/coins/images/7697/small/N7aEdYrY_400x400.png?1561587437',
  },
  KRILL: {
    name: 'Krill',
    address: '0x05089C9EBFFa4F0AcA269e32056b1b36B37ED71b',
    symbol: 'KRILL',
    decimals: 18,
    chainId: 137,
    logoURI: 'https://i.imgur.com/REyP9yh.jpg',
  },
  LEND: {
    name: 'EthLend Token',
    address: '0x313d009888329C9d1cf4f75CA3f32566335bd604',
    symbol: 'LEND',
    decimals: 18,
    chainId: 137,
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x80fB784B7eD66730e8b1DBd9820aFD29931aab03/logo.png',
  },
  LINK: {
    name: 'ChainLink Token',
    address: '0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39',
    symbol: 'LINK',
    decimals: 18,
    website: 'https://chain.link/',
    description:
      'Link is the currency used to pay the Chainlink node operators for their work. Chainlink node operators have to stake LINK in the network in order to participate and provide data services.',
    chainId: 137,
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x514910771AF9Ca656af840dff83E8264EcF986CA/logo.png',
  },
  maAAVE: {
    name: 'Matic Aave interest bearing AAVE',
    address: '0x823CD4264C1b951C9209aD0DeAea9988fE8429bF',
    symbol: 'maAAVE',
    decimals: 18,
    chainId: 137,
    logoURI: 'https://aavegotchi.com/images/matokens/maAAVE.svg',
  },
  maDAI: {
    name: 'Matic Aave interest bearing DAI',
    address: '0xE0b22E0037B130A9F56bBb537684E6fA18192341',
    symbol: 'maDAI',
    decimals: 18,
    chainId: 137,
    logoURI: 'https://aavegotchi.com/images/matokens/maDAI.svg',
  },
  maLINK: {
    name: 'Matic Aave interest bearing LINK',
    address: '0x98ea609569bD25119707451eF982b90E3eb719cD',
    symbol: 'maLINK',
    decimals: 18,
    chainId: 137,
    logoURI: 'https://aavegotchi.com/images/matokens/maLINK.svg',
  },
  MANA: {
    name: 'Decentraland MANA',
    address: '0xA1c57f48F0Deb89f569dFbE6E2B7f46D33606fD4',
    symbol: 'MANA',
    decimals: 18,
    chainId: 137,
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x0F5D2fB29fb7d3CFeE444a200298f468908cC942/logo.png',
  },
  maTUSD: {
    name: 'Matic Aave interest bearing TUSD',
    address: '0xF4b8888427b00d7caf21654408B7CBA2eCf4EbD9',
    symbol: 'maTUSD',
    decimals: 18,
    chainId: 137,
    logoURI: 'https://aavegotchi.com/images/matokens/maTUSD.svg',
  },
  maUNI: {
    name: 'Matic Aave interest bearing UNI',
    address: '0x8c8bdBe9CeE455732525086264a4Bf9Cf821C498',
    symbol: 'maUNI',
    decimals: 18,
    chainId: 137,
    logoURI: 'https://aavegotchi.com/images/matokens/maUNI.svg',
  },
  maUSDC: {
    name: 'Matic Aave interest bearing USDC',
    address: '0x9719d867A500Ef117cC201206B8ab51e794d3F82',
    symbol: 'maUSDC',
    decimals: 6,
    chainId: 137,
    logoURI: 'https://aavegotchi.com/images/matokens/maUSDC.svg',
  },
  maUSDT: {
    name: 'Matic Aave interest bearing USDT',
    address: '0xDAE5F1590db13E3B40423B5b5c5fbf175515910b',
    symbol: 'maUSDT',
    decimals: 6,
    chainId: 137,
    logoURI: 'https://aavegotchi.com/images/matokens/maUSDT.svg',
  },
  maWETH: {
    name: 'Matic Aave interest bearing WETH',
    address: '0x20D3922b4a1A8560E1aC99FBA4faDe0c849e2142',
    symbol: 'maWETH',
    decimals: 18,
    chainId: 137,
    logoURI: 'https://aavegotchi.com/images/matokens/maWETH.svg',
  },
  maYFI: {
    name: 'Matic Aave interest bearing YFI',
    address: '0xe20f7d1f0eC39C4d5DB01f53554F2EF54c71f613',
    symbol: 'maYFI',
    decimals: 18,
    chainId: 137,
    logoURI: 'https://aavegotchi.com/images/matokens/maYFI.svg',
  },
  mDEF: {
    name: 'Matic Deflect Protocol',
    address: '0x82B6205002ecd05e97642D38D61e2cFeaC0E18cE',
    symbol: 'mDEF',
    decimals: 9,
    chainId: 137,
    logoURI: 'https://etherscan.io/token/images/deflect_32.png?=v1',
  },
  miMATIC: MAI,
  MAI: MAI,
  mOCEAN: {
    name: 'Ocean Token',
    address: '0x282d8efCe846A88B159800bd4130ad77443Fa1A1',
    symbol: 'mOCEAN',
    decimals: 18,
    chainId: 137,
    website: 'https://oceanprotocol.com/',
    description:
      'Ocean Protocol unlocks the value of data. Data owners and consumers use Ocean Market app to publish, discover, and consume data in a secure, privacy-preserving fashion. OCEAN holders stake liquidity to data pools. Developers use Ocean libraries to build their own data wallets, data marketplaces, and more. Ocean datatokens wrap data services as industry-standard ERC20 tokens. This enables data wallets, data exchanges, and data co-ops by leveraging crypto wallets, exchanges, and other decentralized finance (DeFi) tools.',
    logoURI: 'https://oceanprotocol.com/static/4ad704a150d436a1f32d495413fc47cd/favicon-white.png',
  },
  MONA: {
    name: 'Monavale',
    address: '0x6968105460f67c3BF751bE7C15f92F5286Fd0CE5',
    symbol: 'MONA',
    decimals: 18,
    chainId: 137,
    logoURI: 'https://i.imgur.com/FR12tmm.jpg',
  },
  mRBAL: {
    name: 'Matic Rebalance Token',
    address: '0x66768ad00746aC4d68ded9f64886d55d5243f5Ec',
    symbol: 'mRBAL',
    decimals: 18,
    chainId: 137,
    logoURI: 'https://rebalancetoken.io/images/logo/logo.png',
  },
  MUST: {
    name: 'Must',
    address: '0x9C78EE466D6Cb57A4d01Fd887D2b5dFb2D46288f',
    symbol: 'MUST',
    decimals: 18,
    chainId: 137,
    website: 'https://www.cometh.io/',
    description:
      'Explore the galaxy and mine precious tokens out of asteroids. Cometh is a defi powered game with yield generating NFT. Get a spaceship, explore the galaxy and earn tokens.',
    logoURI: 'https://etherscan.io/token/images/cometh_32.png',
  },
  NFTP: {
    name: 'NFT Platform Index',
    address: '0xf7d9e281c5Cb4C6796284C5b663b3593D2037aF2',
    symbol: 'NFTP',
    decimals: 18,
    chainId: 137,
    logoURI:
      'https://raw.githubusercontent.com/sameepsi/quickswap-default-token-list/master/assets/NFTP.png',
  },
  OM: {
    name: 'OM',
    address: '0xC3Ec80343D2bae2F8E680FDADDe7C17E71E114ea',
    symbol: 'OM',
    decimals: 18,
    chainId: 137,
    logoURI: 'https://etherscan.io/token/images/mantradao_32.png',
  },
  OPU: {
    name: 'Opu Coin',
    address: '0x7ff2FC33E161E3b1C6511B934F0209D304267857',
    symbol: 'OPU',
    decimals: 18,
    chainId: 137,
    logoURI: 'https://www.opucoin.io/wp-content/uploads/2021/04/opu-coiin-icon-border.svg',
  },
  PICKLE: {
    name: 'Pickle Token',
    address: '0x2b88aD57897A8b496595925F43048301C37615Da',
    symbol: 'PICKLE',
    decimals: 18,
    chainId: 137,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7022.png',
    website: 'https://www.pickle.finance/',
    description:
      'Pickle Finance is a decentralized finance yield farming protocol built on the Ethereum and Polygon blockchain.',
  },
  PLOT: {
    name: 'PLOT',
    address: '0xe82808eaA78339b06a691fd92E1Be79671cAd8D3',
    symbol: 'PLOT',
    decimals: 18,
    chainId: 137,
    logoURI: 'https://i.imgur.com/nQDG9AQ.png',
  },
  PolyDoge: {
    name: 'PolyDoge',
    address: '0x8A953CfE442c5E8855cc6c61b1293FA648BAE472',
    symbol: 'PolyDoge',
    decimals: 18,
    chainId: 137,
    website: 'https://www.polydoge.com/',
    description:
      'PolyDoge is a digital currency with one of the most vibrant communities on the powerful Polygon Network. It offers a wide variety of fun interaction to its holders in the form of NFTs, apps, airdrops and access to different DeFi platforms ready to use on Polygon.',
    logoURI: 'https://polydoge.com/doge-webpage_files/doge.png',
  },
  polyBUNNY: {
    name: 'Bunny',
    address: '0x4C16f69302CcB511c5Fac682c7626B9eF0Dc126a',
    symbol: 'polyBUNNY',
    decimals: 18,
    chainId: 137,
    website: 'https://polygon.pancakebunny.finance/pool',
    description:
      'PancakeBunny is a new and rapidly growing DeFi yield aggregator that is used for PancakeSwap. The PancakeBunny protocol empowers farmers to leverage their yield-seeking tendencies to optimize yield compounding strategy on BSC. We are providing strategies for the various needs of farmers from the highest yield seekers to the risk reward optimizing smart investors.',
    logoURI:
      'https://raw.githubusercontent.com/PancakeBunny-finance/PolygonBUNNY/main/assets/token-bunny_32x32.png',
  },
  PPDEX: {
    name: 'Pepedex',
    address: '0x127984b5E6d5c59f81DACc9F1C8b3Bdc8494572e',
    symbol: 'PPDEX',
    decimals: 18,
    chainId: 137,
    logoURI: 'https://etherscan.io/token/images/pepedex_32.png?v=2',
  },
  pSWAMP: {
    name: 'pSwamp',
    address: '0x5f1657896B38c4761dbc5484473c7A7C845910b6',
    symbol: 'pSWAMP',
    decimals: 18,
    chainId: 137,
    logoURI: 'https://swamp.finance/polygon/static/frontend/img/symbols/pswamp.svg',
  },
  PUSD: {
    name: 'PUSD',
    address: '0x9aF3b7DC29D3C4B1A5731408B6A9656fA7aC3b72',
    symbol: 'PUSD',
    decimals: 18,
    chainId: 137,
    logoURI: 'https://app.polyquity.org/static/media/icon-lqty.06b7d013.svg',
  },
  PYQ: {
    name: 'PYQ',
    address: '0x5a3064CbDCCF428ae907796cF6aD5a664CD7F3d8',
    symbol: 'PYQ',
    decimals: 18,
    chainId: 137,
    logoURI: 'https://app.polyquity.org/static/media/icon-lqty1.3df5c310.svg',
  },
  QI: {
    name: 'Qi Dao',
    address: '0x580A84C73811E1839F75d86d75d88cCa0c241fF4',
    symbol: 'QI',
    decimals: 18,
    chainId: 137,
    website: 'https://www.mai.finance/',
    description:
      'Qi (pronounced CHEE) is the governance token of the QiDao Protocol. It allows those who hold it to vote on changes to the QiDao Protocol. ',
    logoURI: 'https://raw.githubusercontent.com/0xlaozi/qidao/main/images/qi.png',
  },
  QUICK: {
    name: 'Quickswap',
    address: '0x831753DD7087CaC61aB5644b308642cc1c33Dc13',
    symbol: 'QUICK',
    decimals: 18,
    chainId: 137,
    website: 'https://quickswap.exchange/#/swap',
    description: 'Next-gen Layer 2 DEX. Trade at lightning-fast speeds with near-zero gas fees.',
    logoURI:
      'https://raw.githubusercontent.com/sameepsi/quickswap-interface/master/public/favicon.jpeg',
  },
  RBAL: {
    name: 'Rebalance Token',
    address: '0x03247a4368A280bEc8133300cD930A3a61d604f6',
    symbol: 'RBAL',
    decimals: 18,
    chainId: 137,
    logoURI: 'http://rebalancetoken.io/images/logo/RBAL_ERC20_small_001_256.png',
  },
  renDGB: {
    name: 'DigiByte',
    address: '0x2628568509E87c4429fBb5c664Ed11391BE1BD29',
    symbol: 'renDGB',
    decimals: 8,
    chainId: 137,
    logoURI: 'https://etherscan.io/token/images/rendgb_32.png',
    website: 'https://digibyte.org/',
    description:
      'renDGB is the wrapped DigiByte (DGB) token for the DGB open source blockchain and asset creation platform.',
  },
  SDO: {
    name: 'SafeDollar.Fi',
    address: '0x86BC05a6f65efdaDa08528Ec66603Aef175D967f',
    symbol: 'SDO',
    decimals: 18,
    chainId: 137,
    logoURI:
      'https://storage.googleapis.com/polydex/tokens/0x86BC05a6f65efdaDa08528Ec66603Aef175D967f.png',
  },
  SDS: {
    name: 'SafeDollar Share',
    address: '0x352db329B707773DD3174859F1047Fb4Fd2030BC',
    symbol: 'SDS',
    decimals: 18,
    chainId: 137,
    logoURI:
      'https://storage.googleapis.com/polydex/tokens/0x352db329B707773DD3174859F1047Fb4Fd2030BC.png',
  },
  SENT: {
    name: 'Sentinel',
    address: '0x48e3883233461C2eF4cB3FcF419D6db07fb86CeA',
    symbol: 'SENT',
    decimals: 8,
    chainId: 137,
    logoURI: 'https://cdn-images-1.medium.com/max/1200/1*mK1oPGsQWh4Nfupg-e0S-g.png',
  },
  SOL: {
    name: 'Solana',
    address: '0x7DfF46370e9eA5f0Bad3C4E29711aD50062EA7A4',
    symbol: 'SOL',
    decimals: 18,
    chainId: 137,
    logoURI:
      'https://assets.coingecko.com/coins/images/4128/large/coinmarketcap-solana-200.png?1616489452',
    website: 'https://solana.com/',
    description:
      'The Solana protocol is designed to facilitate decentralized app (DApp) creation. It aims to improve scalability by introducing a proof-of-history (PoH) consensus combined with the underlying proof-of-stake (PoS) consensus of the blockchain.',
  },
  START: {
    name: 'Starter',
    address: '0x6Ccf12B480A99C54b23647c995f4525D544A7E72',
    symbol: 'START',
    decimals: 18,
    chainId: 137,
    logoURI: 'https://polygonscan.com/token/images/starter_32.png',
    website: 'https://starter.xyz/',
    description: 'Decentralized fundraising for the Polygon Network.',
  },
  SUPER: {
    name: 'SuperFarm',
    address: '0xa1428174F516F527fafdD146b883bB4428682737',
    symbol: 'SUPER',
    decimals: 18,
    chainId: 137,
    logoURI: 'https://assets.coingecko.com/coins/images/14040/small/6YPdWn6.png?1613975899',
  },
  SWAP: {
    name: 'TrustSwap Token',
    address: '0x3809dcDd5dDe24B37AbE64A5a339784c3323c44F',
    symbol: 'SWAP',
    decimals: 18,
    chainId: 137,
    logoURI: 'https://i.imgur.com/vZnU36G.png',
  },
  SWG: {
    name: 'Swirge',
    address: '0x043A3Aa319B563aC25D4E342d32bFfb51298DB7b',
    symbol: 'SWG',
    decimals: 18,
    chainId: 137,
    logoURI: 'https://etherscan.io/token/images/swirge_32.png',
  },
  SX: {
    name: 'SportX',
    address: '0x840195888Db4D6A99ED9F73FcD3B225Bb3cB1A79',
    symbol: 'SX',
    decimals: 18,
    chainId: 137,
    logoURI:
      'https://raw.githubusercontent.com/sameepsi/quickswap-default-token-list/master/assets/sx.jpg',
  },
  TEL: {
    name: 'Telcoin',
    address: '0xdF7837DE1F2Fa4631D716CF2502f8b230F1dcc32',
    symbol: 'TEL',
    decimals: 2,
    chainId: 137,
    website: 'https://www.telco.in/',
    description: 'A cryptocurrency distributed by your mobile operator and accepted everywhere.',
    logoURI: 'https://pbs.twimg.com/profile_images/933388441475194881/57fOk40N_400x400.jpg',
  },
  UBT: {
    name: 'Unibright',
    address: '0x7FBc10850caE055B27039aF31bD258430e714c62',
    symbol: 'UBT',
    decimals: 8,
    chainId: 137,
    website: 'https://unibright.io/',
    description:
      'Unibright is a team of blockchain specialists, architects, developers and consultants with 20+ years of experience in business processes and integration. We offer Baseledger - The Blockchain for Baselining, Consulting, Low-Code-Integration Tools, programmable DeFi and the Universal Business Token.',
    logoURI:
      'https://assets.coingecko.com/coins/images/2707/small/UnibrightLogo_colorful_500x500_preview.png?1547036916',
  },
  UNI: {
    name: 'Uniswap',
    address: '0xb33EaAd8d922B1083446DC23f610c2567fB5180f',
    symbol: 'UNI',
    decimals: 18,
    website: 'https://uniswap.org/',
    description:
      'UNI is the governance token for Uniswap. UNI was introduced on 16th September 2020 through a retrospective airdrop to users who have interacted with the protocol either by swapping tokens or by providing liquidity.',
    chainId: 137,
    logoURI:
      'https://raw.githubusercontent.com/sameepsi/quickswap-interface/master/public/favicon1.png',
  },
  USDC: {
    name: 'USD Coin',
    address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    symbol: 'USDC',
    decimals: 6,
    website: 'https://www.circle.com/usdc',
    description:
      'USDC is a fully collateralized US dollar stablecoin. USDC is issued by regulated financial institutions, backed by fully reserved assets, redeemable on a 1:1 basis for US dollars.',
    chainId: 137,
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png',
  },
  USDT: {
    name: 'Tether USD',
    address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    symbol: 'USDT',
    decimals: 6,
    chainId: 137,
    website: 'https://tether.to/',
    description:
      'Tether converts cash into digital currency, to anchor or tether the value to the price of national currencies like the US dollar, the Euro, and the offshore Chinese yuan.',
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png',
  },
  VISION: {
    name: 'Vision Token',
    address: '0x034b2090b579228482520c589dbD397c53Fc51cC',
    symbol: 'VISION',
    decimals: 18,
    chainId: 137,
    website: 'https://matic.apy.vision/',
    description: 'All-in-one liquidity pool analytics and yield farming rewards tracking tool',
    logoURI: 'https://s3-us-west-2.amazonaws.com/acf-uploads/apyvisionlogo200circle.png',
  },
  WBTC: {
    name: 'Wrapped BTC',
    address: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
    symbol: 'WBTC',
    decimals: 8,
    website: 'https://wbtc.network/',
    description:
      'Wrapped Bitcoin (WBTC) is the first ERC20 token backed 1:1 with Bitcoin. Completely transparent. 100% verifiable. Community led.',
    chainId: 137,
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
  },
  WEXpoly: {
    name: 'WEXPoly',
    address: '0x4c4BF319237D98a30A929A96112EfFa8DA3510EB',
    symbol: 'WEXPoly',
    decimals: 18,
    chainId: 137,
    website: 'https://wault.finance/',
    description:
      'WEXpoly, or Wault EXchange Token, is the foundational token of WaultSwap, on the Polygon network. ',
    logoURI:
      'https://assets.coingecko.com/coins/images/16282/small/ws_purple_circle_200x200_%281%29.png',
  },
  WISE: {
    name: 'Wise Token',
    address: '0xB77e62709e39aD1cbeEBE77cF493745AeC0F453a',
    symbol: 'WISE',
    decimals: 18,
    chainId: 137,
    website: 'https://wisetoken.net/',
    description:
      'WISE is an asset-backed cryptocurrency designed to be a highly secure store of value.',
    logoURI: 'https://etherscan.io/token/images/wisetoken_32.png',
  },
  MATIC: MATIC,
  WMATIC: MATIC,
  WNATIVE: MATIC,
  WOLF: {
    name: 'moonwolf.io',
    address: '0x8f18dC399594b451EdA8c5da02d0563c0b2d0f16',
    symbol: 'WOLF',
    decimals: 9,
    chainId: 137,
    logoURI: 'https://i.imgur.com/UIjlQpC.png',
  },
  WOO: {
    name: 'Wootrade Network',
    address: '0x1B815d120B3eF02039Ee11dC2d33DE7aA4a8C603',
    symbol: 'WOO',
    decimals: 18,
    chainId: 137,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/200x200/7501.png',
    website: 'https://woo.network/',
    description:
      'Wootrade is a layer one trading infrastructure complete with deep liquidity, frontend trading GUI, and the ability to integrate into any trading-related platform.',
  },
  WRX: {
    name: 'WazirX',
    address: '0x72d6066F486bd0052eefB9114B66ae40e0A6031a',
    symbol: 'WRX',
    decimals: 8,
    chainId: 137,
    logoURI: 'https://etherscan.io/token/images/binance-wrx_32.png',
  },
  xMARK: {
    name: 'Standard',
    address: '0xf153EfF70DC0bf3b085134928daeEA248d9B30d0',
    symbol: 'xMARK',
    decimals: 9,
    chainId: 137,
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/8cb78aca77b340510958ed98a3cd260d2d7f0420/blockchains/ethereum/assets/0x36b679bd64Ed73DBfd88909cDCB892cB66Bd4CBb/logo.png',
    website: 'https://benchmarkprotocol.finance/',
    description:
      'Benchmark Protocol is an elastic stablecoin-alternative bridging capital markets to DeFi.',
  },
  YFI: {
    name: 'yearn.finance',
    address: '0xDA537104D6A5edd53c6fBba9A898708E465260b6',
    symbol: 'YFI',
    decimals: 18,
    chainId: 137,
    website: 'https://yearn.finance/',
    description:
      'Yearn Finance is a suite of products in Decentralized Finance (DeFi) that provides lending aggregation, yield generation, and insurance on the Ethereum blockchain. The protocol is maintained by various independent developers and is governed by YFI holders.',
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e/logo.png',
  },
  ZUT: {
    name: 'ZeroUtility',
    address: '0xe86E8beb7340659DDDCE61727E500e3A5aD75a90',
    symbol: 'ZUT',
    decimals: 18,
    chainId: 137,
    logoURI: 'https://s2.gifyu.com/images/zutlogo.jpg',
  },
  ZUZ: {
    name: 'Zeus',
    address: '0x232eaB56c4fB3f84c6Fb0a50c087c74b7B43c6Ad',
    symbol: 'ZUZ',
    decimals: 18,
    chainId: 137,
    logoURI: 'https://etherscan.io/token/images/zuzprotocol_32.png',
  },
  ROUTE: {
    name: 'Route',
    address: '0x16ECCfDbb4eE1A85A33f3A9B21175Cd7Ae753dB4',
    symbol: 'ROUTE',
    decimals: 18,
    chainId: 137,
    website: 'https://routerprotocol.com/',
    description:
      'Router is developing the bridging infrastructure to allow contract level data flow across various blockchains, thus enabling asset level data transfer.',
    logoURI: 'https://assets.coingecko.com/coins/images/13709/small/route_token_200x200-19.png',
  },
  DFYN: {
    name: 'Dfyn',
    address: '0xC168E40227E4ebD8C1caE80F7a55a4F0e6D66C97',
    symbol: 'DFYN',
    decimals: 18,
    chainId: 137,
    website: 'https://exchange.dfyn.network/#/swap',
    description:
      'With Dfyn nodes spread across multiple Layer 1 and Layer 2 blockchains, Dfyn will act as a multi-chain DEX. This will allow Dfyn to plug into a liquidity super-mesh, enabling users to perform their asset trades seamlessly on several blockchains from a single interface. Dfyn will also build a decentralized swap station to swap large assets natively like BTC, ETH, ADA in a non-custodial way.',
    logoURI: 'https://raw.githubusercontent.com/dfyn/assets/main/DFYN_logo.png',
  },
  TITAN: {
    name: 'Titan',
    address: '0xaAa5B9e6c589642f98a1cDA99B9D024B8407285A',
    symbol: 'TITAN',
    decimals: 18,
    chainId: 137,
    logoURI:
      'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0xaAa5B9e6c589642f98a1cDA99B9D024B8407285A/logo.png',
  },
  IRON: {
    name: 'Iron',
    address: '0xD86b5923F3AD7b585eD81B448170ae026c65ae9a',
    symbol: 'IRON',
    decimals: 18,
    chainId: 137,
    logoURI: 'https://polygon.iron.finance/static/media/IRON.484ee2b8.png',
  },
  SUSHI: {
    name: 'Sushi',
    address: '0x0b3F868E0BE5597D5DB7fEB59E1CADBb0fdDa50a',
    symbol: 'SUSHI',
    decimals: 18,
    chainId: 137,
    website: 'https://sushi.com/',
    description:
      'Sushi is the home of DeFi. Their community is building a comprehensive, decentralized trading platform for the future of finance. Swap, earn, stack yields, lend, borrow, leverage all on one decentralized, community driven platform.',
    logoURI: 'https://app.sushi.com/static/media/logo.11fafaa5.png',
  },
  GRT: {
    name: 'The Graph',
    address: '0x5fe2B58c013d7601147DcdD68C143A77499f5531',
    symbol: 'GRT',
    decimals: 18,
    chainId: 137,
    website: 'https://thegraph.com/',
    description:
      'The Graph is an indexing protocol for querying networks like Ethereum and IPFS. Anyone can build and publish open APIs, called subgraphs, making data easily accessible.',
    logoURI:
      'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x5fe2B58c013d7601147DcdD68C143A77499f5531/logo.png',
  },
  WOOFY: {
    name: 'Woofy',
    address: '0xD0660cD418a64a1d44E9214ad8e459324D8157f1',
    symbol: 'WOOFY',
    decimals: 12,
    chainId: 137,
    website: 'https://woofy.finance/',
    description:
      'The WOOFY token is the blue dog companion token of YFI. Utilizing special Woof technology, it allows two-way conversion between the two tokens, allowing holders to be exposed to YFI in a fluffier package.',
    logoURI:
      'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0xD0660cD418a64a1d44E9214ad8e459324D8157f1/logo.png',
  },
  renDOGE: {
    name: 'renDOGE',
    address: '0xcE829A89d4A55a63418bcC43F00145adef0eDB8E',
    symbol: 'renDOGE',
    decimals: 8,
    chainId: 137,
    website: 'https://renproject.io/',
    description: 'renDOGE is a one-for-one representation of Dogecoin (DOGE) on Polygon via RenVM.',
    logoURI: 'https://polygonscan.com/token/images/rendogecoin_32.png',
  },
  SNX: {
    name: 'SNX',
    address: '0x50B728D8D964fd00C2d0AAD81718b71311feF68a',
    symbol: 'SNX',
    decimals: 18,
    chainId: 137,
    website: 'https://synthetix.io/',
    description:
      'Synthetix is the backbone for derivatives trading in DeFi, allowing anyone, anywhere to gain on-chain exposure to a vast range of assets.',
    logoURI: 'https://app.sushi.com/images/tokens/snx-square.jpg',
  },
  CRV: {
    name: 'Curve Protocol',
    symbol: 'CRV',
    address: '0x172370d5Cd63279eFa6d502DAB29171933a610AF',
    chainId: 137,
    decimals: 18,
    website: 'https://curve.fi/',
    description:
      'Curve is an exchange liquidity pool on Ethereum. Curve is designed for extremely efficient stablecoin trading and low risk, supplemental fee income for liquidity providers, without an opportunity cost.',
    logoURI: 'https://external-content.duckduckgo.com/ip3/resources.curve.fi.ico',
  },
  DOKI: {
    name: 'DokiDoki Finance',
    symbol: 'DOKI',
    address: '0x5C7F7Fe4766fE8f0fa9b41E2E4194d939488ff1C',
    chainId: 137,
    decimals: 18,
    website: 'https://dokidoki.finance/stake/pool/1',
    description: 'Doki is the platform token for DokiDoki Finance.',
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x9cEB84f92A0561fa3Cc4132aB9c0b76A59787544/logo.png',
  },
  PZAP: {
    name: 'PolyZap',
    symbol: 'PZAP',
    address: '0xeb2778f74E5ee038E67AA6c77f0F0451ABd748FD',
    chainId: 137,
    decimals: 18,
    website: 'https://farm.polyzap.finance/',
    description:
      'PolyZap is an innovative and reliable, next-generation Automated Market Maker (AMM) and Yield Farm on the Polygon Network, with unique features and attributes:',
    logoURI: 'https://polyzap.finance/logo.png',
  },
  GBULL: {
    name: 'GoldenBull Finance',
    symbol: 'GBULL',
    address: '0x3E9B01762a82C12151CDE2094F8EF9BCAb774C8E',
    chainId: 137,
    decimals: 18,
    logoURI: 'https://farms.goldenbull.finance/images/gbull/gbull-logo.png',
  },
  ADDY: {
    name: 'Adamant Finance',
    symbol: 'ADDY',
    address: '0xc3FdbadC7c795EF1D6Ba111e06fF8F16A20Ea539',
    chainId: 137,
    decimals: 18,
    logoURI: 'https://adamant.finance/img/ADDY.png',
  },
  POLYGOLD: {
    name: 'POLYGOLD',
    symbol: 'POLYGOLD',
    address: '0x0184316f58B9A44aCDD3e683257259dC0CF2202a',
    chainId: 137,
    decimals: 18,
    logoURI: 'https://polygold.finance/images/egg/9.png',
  },
  PAUTO: {
    name: 'Autofarm.network',
    symbol: 'PAUTO',
    address: '0x7f426F6Dc648e50464a0392E60E1BB465a67E9cf',
    chainId: 137,
    decimals: 18,
    logoURI: 'https://autofarm.network/logo-round.svg',
  },
  IQ: {
    name: 'Everipedia IQ',
    symbol: 'IQ',
    address: '0xB9638272aD6998708de56BBC0A290a1dE534a578',
    chainId: 137,
    decimals: 18,
    logoURI: 'https://i.imgur.com/2Tocoq5.png',
  },
  FFF: {
    name: 'Future of Finance Fund',
    symbol: 'FFF',
    address: '0x9aCeB6f749396d1930aBc9e263eFc449E5e82c13',
    chainId: 137,
    decimals: 18,
    website: 'https://indexed.finance/',
    description:
      'A meta-index providing all-in-one exposure to Bitcoin and Ether (20% each), with the remainder weighted across DEFI5, CC10 and DEGEN using circulating market cap weightings',
    logoURI: 'https://assets.coingecko.com/coins/images/15761/small/xg1NFl0.png?1621825451',
  },
  UFT: {
    name: 'UniLend Finance Token',
    symbol: 'UFT',
    address: '0x5B4CF2C120A9702225814E18543ee658c5f8631e',
    chainId: 137,
    decimals: 18,
    website: 'https://unilend.finance/',
    description:
      'UniLend is a permission-less decentralized protocol that combines spot trading services and money markets with lending and borrowing services through smart contracts.',
    logoURI:
      'https://assets.coingecko.com/coins/images/12819/small/UniLend_Finance_logo_PNG.png?1602748658',
  },
  CHUM: {
    name: 'ChumHum',
    symbol: 'CHUM',
    address: '0x2e2DDe47952b9c7deFDE7424d00dD2341AD927Ca',
    chainId: 137,
    decimals: 18,
    logoURI: 'https://i.imgur.com/66lM7Rx.png',
  },
  ELE: {
    name: 'Eleven.finance',
    symbol: 'ELE',
    address: '0xAcD7B3D9c10e97d0efA418903C0c7669E702E4C0',
    chainId: 137,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/14541/small/eleven_finance_logo.png?1616895791',
  },
  WFIL: {
    name: 'Wrapped Filecoin',
    symbol: 'WFIL',
    address: '0xEde1B77C0Ccc45BFa949636757cd2cA7eF30137F',
    chainId: 137,
    decimals: 18,
    website: 'https://filecoin.io/',
    description:
      'Filecoin is an open-source cloud storage marketplace, protocol, and cryptocurrency.',
    logoURI:
      'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0xEde1B77C0Ccc45BFa949636757cd2cA7eF30137F/logo.png',
  },
  BOOTY: {
    name: 'PirateBooty',
    symbol: 'BOOTY',
    address: '0xD12DC5319808Bb31ba95AE5764def2627d5966CE',
    chainId: 137,
    decimals: 18,
    logoURI: 'https://docs.piratedice.xyz/logo.png',
  },
  dTOP: {
    name: 'dHEDGE Top Index',
    symbol: 'dTOP',
    address: '0x0361BdEAB89DF6BBcc52c43589FABba5143d19dD',
    chainId: 137,
    decimals: 18,
    logoURI: 'https://app.dhedge.org/favicon.ico',
  },
  ibBTC: {
    name: 'Interest bearing BTC',
    symbol: 'ibBTC',
    address: '0x4EaC4c4e9050464067D673102F8E24b2FccEB350',
    chainId: 137,
    decimals: 18,
    website: 'https://app.badger.finance/ibBTC',
    description:
      'ibBTC is an asset launched in collaboration with DeFiDollar, it stands for Interest Bearing Bitcoin. It was created to serve as the default Bitcoin asset on Ethereum while generating interest to the users who hold it.',
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
  },
  BNB: {
    name: 'AnySwap Bridge Polygon Binance Coin',
    symbol: 'BNB',
    address: '0xA649325Aa7C5093d12D6F98EB4378deAe68CE23F',
    chainId: 137,
    decimals: 18,
    website: 'https://www.binance.com/',
    description:
      'Binance Coin (BNB) is an exchange-based token created and issued by the cryptocurrency exchange Binance. Initially created on the Ethereum blockchain as an ERC-20 token in July 2017, BNB was migrated over to Binance Chain in February 2019 and became the native coin of the Binance Chain.',
    logoURI:
      'https://pancakeswap.finance/images/tokens/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c.png',
  },
  rBNB: {
    name: 'Relay Bridge Polygon Binance Coin',
    symbol: 'BNB',
    address: '0x5c4b7CCBF908E64F32e12c6650ec0C96d717f03F',
    chainId: 137,
    decimals: 18,
    website: 'https://www.binance.com/',
    description:
      'Binance Coin (BNB) is an exchange-based token created and issued by the cryptocurrency exchange Binance. Initially created on the Ethereum blockchain as an ERC-20 token in July 2017, BNB was migrated over to Binance Chain in February 2019 and became the native coin of the Binance Chain.',
    logoURI:
      'https://pancakeswap.finance/images/tokens/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c.png',
  },
  PBNB: {
    name: 'Orbit Bridge Polygon Binance Coin',
    symbol: 'PBNB',
    address: '0x7e9928aFe96FefB820b85B4CE6597B8F660Fe4F4',
    chainId: 137,
    decimals: 18,
    website: 'https://www.binance.com/',
    description:
      'Binance Coin (BNB) is an exchange-based token created and issued by the cryptocurrency exchange Binance. Initially created on the Ethereum blockchain as an ERC-20 token in July 2017, BNB was migrated over to Binance Chain in February 2019 and became the native coin of the Binance Chain.',
    logoURI:
      'https://pancakeswap.finance/images/tokens/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c.png',
  },
  YELD: {
    name: 'Polyyeld.Finance',
    symbol: 'YELD',
    address: '0xd0f3121A190d85dE0AB6131f2bCEcdbfcfB38891',
    chainId: 137,
    decimals: 18,
    website: 'https://polyyeld.finance/',
    description:
      'PolyYeld Finance is a next-generation yield farming protocol on the Polygon network with lots of unique and creative features that enable you to earn a passive income.',
    logoURI: 'https://polyyeld.finance/images/egg/9.png',
  },
  xYELD: {
    name: 'Polyyeld.Finance Layer 2',
    symbol: 'xYELD',
    address: '0x90AC3fa9fCD997B168f218041de26eB01399Bb55',
    chainId: 137,
    decimals: 18,
    website: 'https://layer.polyyeld.finance/',
    description:
      "PolyYeld Finance is a next-generation yield farming protocol on the Polygon network with lots of unique and creative features that enable you to earn a passive income. xYeld is the native token for Polyeld's layer 2 farms",
    logoURI: 'https://layer.polyyeld.finance/images/egg/Logo_Text_Horizontal.svg',
  },
  PUP: {
    name: 'Polypup.Finance',
    symbol: 'PUP',
    address: '0xcFe2cF35D2bDDE84967e67d00aD74237e234CE59',
    chainId: 137,
    decimals: 18,
    website: 'https://www.polypup.finance/',
    description:
      'PolyPup Finance is a new DeFi project on Polygon featuring a deflationary token model with a maximum supply of 31,000 PUP tokens.',
    logoURI: 'https://polypup.finance/images/egg/9.png',
  },
  rUSD: {
    name: 'Ramp USD',
    symbol: 'rUSD',
    address: '0xfC40a4F89b410a1b855b5e205064a38fC29F5eb5',
    chainId: 137,
    decimals: 18,
    website: 'https://rampdefi.com/',
    description:
      'Ramp aims to empower users with access to a global, blockchain-agnostic liquidity network. rUSD can be minted on Binance Smart Chain and Polygon, with more chains in the near future.',
    logoURI: 'https://appv2.rampdefi.com/assets/icon/rusdc.svg',
  },
  RAMP: {
    name: 'Ramp DeFi',
    symbol: 'RAMP',
    address: '0xaECeBfcF604AD245Eaf0D5BD68459C3a7A6399c2',
    chainId: 137,
    decimals: 18,
    website: 'https://rampdefi.com/',
    description:
      'Ramp aims to empower users with access to a global, blockchain-agnostic liquidity network. rUSD can be minted on Binance Smart Chain and Polygon, with more chains in the near future.',
    logoURI: 'https://appv2.rampdefi.com/assets/icon/ramp.png',
  },
  UST: {
    name: 'USD Terra',
    symbol: 'UST',
    address: '0x692597b009d13C4049a947CAB2239b7d6517875F',
    chainId: 137,
    decimals: 18,
    website: 'https://www.terra.money/',
    description:
      'Terra stablecoins offer instant settlements, low fees and seamless cross-border exchange - loved by millions of users and merchants.',
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7129.png',
  },
  WMATIC_DFYN: {
    name: 'Wrapped Matic, DFYN version',
    symbol: 'WMATIC',
    address: '0x4c28f48448720e9000907BC2611F73022fdcE1fA',
    chainId: 137,
    decimals: 18,
    website: 'https://polygon.technology/',
    description:
      'DFyn version. Polygon is a protocol and a framework for building and connecting Ethereum-compatible blockchain networks. Aggregating scalable solutions on Ethereum supporting a multi-chain Ethereum ecosystem.',
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0/logo.png',
  },
  BONE: {
    name: 'Polypup Bone',
    symbol: 'BONE',
    address: '0x6bb45cEAC714c52342Ef73ec663479da35934bf7',
    chainId: 137,
    decimals: 18,
    website: 'https://www.polypup.finance/',
    description:
      'PolyPup Finance is a new DeFi project on Polygon featuring a deflationary token model with a maximum supply of 31,000 PUP tokens.',
    logoURI: 'https://bone.polypup.finance/images/egg/9.png',
  },
  BONEswap: {
    name: 'BoneSwap',
    symbol: 'BONE',
    address: '0x80244C2441779361F35803b8C711C6c8fC6054a3',
    chainId: 137,
    decimals: 18,
    website: 'https://farm.boneswap.finance/',
    description:
      'Bone Swap is a full ecosystem that have yield farming and automated market-making (AMM) for the Polygon network. We introduce Decentralized Exchange (DEX), Yield Farm, Info Service, which is the strong and secure foundation with our own BONE token as the center of our service.',
    logoURI: 'https://farm.boneswap.finance/images/bone/logo.png',
  },
  EZ: {
    name: 'EasyFi',
    symbol: 'EZ',
    address: '0x34C1b299A74588D6Abdc1b85A53345A48428a521',
    chainId: 137,
    decimals: 18,
    website: 'https://easyfi.network/index.html',
    description:
      'Multi chain layer 2 money markets with structured lending products to accelerate liquidity deployment at remarkably lowest cost & unimaginable fast speed.',
    logoURI: 'https://etherscan.io/token/images/easyfi_32.png',
  },
  HEO: {
    name: 'Helios.cash',
    symbol: 'HEO',
    address: '0x03aA5Daf9Fd5f7F33C67AfbCd53c1C1e87c4c9F7',
    chainId: 137,
    decimals: 18,
    logoURI: 'https://www.helios.cash/static/media/logo1024.08eac992.svg',
  },
  BRAIN: {
    name: 'BrainSwap',
    symbol: 'BRAIN',
    address: '0x5C6014246FC7911F4dB270aA3910F23EECD61720',
    chainId: 137,
    decimals: 18,
    logoURI: 'https://brainswap.finance/images/egg/9.png',
  },
  pWINGS: {
    name: 'JetSwap Polygon Token',
    symbol: 'pWINGS',
    address: '0x845E76A8691423fbc4ECb8Dd77556Cb61c09eE25',
    chainId: 137,
    decimals: 18,
    website: 'https://jetswap.finance/',
    description:
      'Jetswap is a decentralized Automated Market Maker (AMM) on Binance Smart Chain & Polygon with low fees and instant trade execution. Trade from the comfort of your own wallet! ',
    logoURI: 'https://polygon-info.jetswap.finance/static/media/wings.dca9f9fe.png',
  },
  HONOR: {
    name: 'FarmHero HONOR',
    symbol: 'HONOR',
    address: '0xb82A20B4522680951F11c94c54B8800c1C237693',
    chainId: 137,
    decimals: 18,
    website: 'https://polygon.farmhero.io/',
    description: 'FarmHero is a protocol that mixes NFT, gaming and DEFI concepts.',
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10620.png',
  },
  ICE: {
    name: 'Iron Finance ICE Token',
    symbol: 'ICE',
    address: '0x4A81f8796e0c6Ad4877A51C86693B0dE8093F2ef',
    chainId: 137,
    decimals: 18,
    website: 'https://iron.finance/',
    description:
      'Iron Finance is building a full suite of decentralized finance ecosystem of products and use cases on the Polygon network. The official Iron Finance token is the ICE token, inspired by A Song of Ice and Fire novels, where the Iron Throne is a well-known symbol.',
    logoURI: 'https://app.iron.finance/static/media/ice-square.6e960434.svg',
  },
  NEXO: {
    name: 'Nexo',
    symbol: 'NEXO',
    address: '0x41b3966B4FF7b427969ddf5da3627d6AEAE9a48E',
    chainId: 137,
    decimals: 18,
    website: 'https://nexo.io/',
    description:
      'Since 2018 Nexo has strived to bring professional financial services to the world of digital assets. Leveraging the best of the team’s years of experience in FinTech along with the power of blockchain technology, Nexo is empowering thousands of people to harness the value behind their crypto assets and live the future of finance today.',
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/200x200/2694.png',
  },
  DINO: {
    name: 'DINO',
    symbol: 'DINO',
    address: '0xAa9654BECca45B5BDFA5ac646c939C62b527D394',
    chainId: 137,
    decimals: 18,
    website: 'https://dinoswap.exchange/',
    description:
      'DinoSwap is a cross-chain infrastructure project that builds liquidity for layer-one blockchains, AMMs and partnering projects.',
    logoURI: 'https://assets.coingecko.com/coins/images/17103/small/DINO.png',
  },
  LITHIUM: {
    name: 'LITHIUM',
    symbol: 'LITHIUM',
    address: '0xfE1a200637464FBC9B60Bc7AeCb9b86c0E1d486E',
    chainId: 137,
    decimals: 18,
    website: 'https://polywantsacracker.farm/',
    description: 'PolyWantsACracker Yield Farm',
    logoURI: 'https://polywantsacracker.farm/images/coins/lithium.svg',
  },
  YAMP: {
    name: 'Yamp.Finance',
    symbol: 'YAMP',
    address: '0x87f654c4b347230C60CAD8d7ea9cF0D7238bcc79',
    chainId: 137,
    decimals: 18,
    website: 'https://yamp.finance/',
    description:
      'Yamp brings the ability for Quickswap liquidity providers to borrow funds to leverage their yields. Whenever there’s a surge in yields, liquidity providers can easily multiply their farming positions many times over to take advantage of increased returns.',
    logoURI: 'https://miro.medium.com/fit/c/262/262/1*Ig_ZORDgALAPAOJEnAJLLw.png',
  },
  LUNA: {
    name: 'LUNA',
    symbol: 'LUNA',
    address: '0x24834BBEc7E39ef42f4a75EAF8E5B6486d3F0e57',
    chainId: 137,
    decimals: 18,
    website: 'https://www.terra.money/',
    description:
      'Luna directly benefits from the economic growth of the Terra economy, and it suffers from contractions of the Terra coin',
    logoURI: 'https://etherscan.io/token/images/terra-luna_32.png',
  },
  BALL: {
    name: 'Polypup Ball',
    symbol: 'BALL',
    address: '0x883aBe4168705d2e5dA925d28538B7a6AA9d8419',
    chainId: 137,
    decimals: 18,
    website: 'https://ball.polypup.finance/',
    description:
      "Ball is PolyPup Finance's 3rd layer, featuring a maximum supply of 94,000 tokens.",
    logoURI: 'https://ball.polypup.finance/images/egg/9.png',
  },
  polyWISE: {
    name: 'Polywise',
    symbol: 'WISE',
    address: '0x4c19DdeebAF84cA3A255730295AD9d824D4Ff51f',
    chainId: 137,
    decimals: 18,
    website: 'https://polywise.finance/',
    description:
      'PolyWise Finance is a next-generation yield farming protocol on the Polygon network with lots of unique and creative features that enable you to earn a passive income. We are trying to create a protocol like Yearn, but with a reduced token supply and high value for Polygon Network users, LP providers and stakers. The max supply of WISE token is 5400.',
  },
  polySAGE: {
    name: 'PolySage',
    symbol: 'SAGE',
    address: '0x2ed945Dc703D85c80225d95ABDe41cdeE14e1992',
    chainId: 137,
    decimals: 18,
    website: 'https://polysage.finance/',
    description:
      'PolySage Finance is a next-generation yield farming protocol on the Polygon network with lots of unique and creative features that enable you to earn a passive income.',
    logoURI: 'https://polysage.finance/images/sage.png',
  },
};
exports.tokens = _tokens;
