'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
exports.polypupBall = exports.polypupBone = exports.polypup = void 0;
exports.polypup = {
  masterchef: '0xCc7E7c9FC775D25176e9Bfc5A400EdAc212aa81C',
  timelock: '0xFA256781ff98ac55f1E531d8217A7EB7Fd54F7a2',
};
exports.polypupBone = {
  masterchef: '0x9DcB2D5e7b5212fAF98e4a152827fd76bD55f68b',
  timelock: '0xdfe4665328DD5C5485A05c2A5252Bb44Bf6E65dc',
};
exports.polypupBall = {
  masterchef: '0xB5F383998d4E58C140c15C441c75bB79170b6b45',
  timelock: '0xCdC4eaB720239Ae18dd13e60488848E49d4d7137',
};
