'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
exports.tokens = void 0;
var ETH = {
  name: 'Wrapped Ether',
  address: '0xC9BdeEd33CD01541e1eeD10f90519d2C06Fe3feB',
  symbol: 'WETH',
  decimals: 18,
  chainId: 1313161554,
  website: 'https://weth.io/',
  description: 'Ether or ETH is the native currency built on the Ethereum blockchain.',
  logoURI: 'https://arbiscan.io/token/images/weth_28.png',
};
var _tokens = {
  ETH: ETH,
  WETH: ETH,
  WNATIVE: ETH,
  BIFI: {
    name: 'Vaporwave.Finance',
    symbol: 'VWAVE',
    address: '0x2451dB68DeD81900C4F16ae1af597E9658689734',
    chainId: 1313161554,
    decimals: 18,
    website: 'https://www.vaporwave.farm/',
    description:
      'Vaporwave Finance is a Decentralized, Multi-Chain Yield Optimizer platform  on Aurora!',
    logoURI:
      'https://raw.githubusercontent.com/beefyfinance/beefy-app/prod/src/images/single-assets/BIFI.png',
  },
  NEAR: {
    name: 'NEAR',
    symbol: 'NEAR',
    address: '0xC42C30aC6Cc15faC9bD938618BcaA1a1FaE8501d',
    chainId: 1313161554,
    decimals: 24,
    logoURI:
      'https://pancakeswap.finance/images/tokens/0xC42C30aC6Cc15faC9bD938618BcaA1a1FaE8501d.svg',
    website: 'https://near.org/',
    description:
      'Through simple, secure, and scalable technology, NEAR empowers millions to invent and explore new experiences. Business, creativity, and community are being reimagined for a more sustainable and inclusive future.',
  },
  USDC: {
    name: 'USD Coin',
    symbol: 'USDC',
    address: '0xB12BFcA5A55806AaF64E99521918A4bf0fC40802',
    chainId: 1313161554,
    decimals: 6,
    logoURI:
      'https://pancakeswap.finance/images/tokens/0xB12BFcA5A55806AaF64E99521918A4bf0fC40802.svg',
    website: 'https://www.circle.com/usdc',
    description:
      'USDC is a fully collateralized US dollar stablecoin. USDC is issued by regulated financial institutions, backed by fully reserved assets, redeemable on a 1:1 basis for US dollars.',
  },
  USDT: {
    name: 'Tether USD',
    symbol: 'USDT',
    address: '0x4988a896b1227218e4A686fdE5EabdcAbd91571f',
    chainId: 1313161554,
    decimals: 6,
    logoURI:
      'https://pancakeswap.finance/images/tokens/0x4988a896b1227218e4A686fdE5EabdcAbd91571f.svg',
    website: 'https://tether.to/',
    description:
      'Tether is a stablecoin pegged to the US Dollar. A stablecoin is a type of cryptocurrency whose value is pegged to another fiat currency like the US Dollar or to a commodity like Gold.Tether is the first stablecoin to be created and it is the most popular stablecoin used in the ecosystem.',
  },
  WBTC: {
    name: 'Wrapped BTC',
    symbol: 'WBTC',
    address: '0xF4eB217Ba2454613b15dBdea6e5f22276410e89e',
    chainId: 1313161554,
    decimals: 8,
    logoURI:
      'https://pancakeswap.finance/images/tokens/0xF4eB217Ba2454613b15dBdea6e5f22276410e89e.svg',
    website: 'https://wbtc.network/',
    description:
      'Wrapped Bitcoin (WBTC) is the first ERC20 token backed 1:1 with Bitcoin. Completely transparent. 100% verifiable. Community led.',
  },
  TRI: {
    name: 'Trisolaris',
    symbol: 'TRI',
    address: '0xFa94348467f64D5A457F75F8bc40495D33c65aBB',
    chainId: 1313161554,
    decimals: 18,
    logoURI:
      'https://pancakeswap.finance/images/tokens/0xFa94348467f64D5A457F75F8bc40495D33c65aBB.svg',
    website: 'https://www.trisolaris.io/#/swap',
    description:
      'Trisolaris is #1 Dex on the Aurora engine, an EVM compatible blockchain running in the near ecosystem.',
  },
  AURORA: {
    name: 'Aurora',
    symbol: 'AURORA',
    address: '0x8BEc47865aDe3B172A928df8f990Bc7f2A3b9f79',
    chainId: 1313161554,
    decimals: 18,
    logoURI:
      'https://pancakeswap.finance/images/tokens/0x8BEc47865aDe3B172A928df8f990Bc7f2A3b9f79.svg',
    website: 'https://www.aurorachain.io/',
    description:
      'Aurora is a decentralized application platform based on third-generation blockchain technology dedicated to providing mature blockchain technology solutions for the entire industry.',
  },
  PAD: {
    name: 'NearPad',
    symbol: 'PAD',
    address: '0x885f8CF6E45bdd3fdcDc644efdcd0AC93880c781',
    chainId: 1313161554,
    decimals: 18,
    logoURI: 'https://dex.nearpad.io/_next/image?url=%2Fimages%2Fcurrencies%2Fpad.jpeg&w=96&q=75',
    website: 'https://dex.nearpad.io/',
    description:
      'The DeFi hub on NEAR ecosystem to bootstrap innovations, monitor and manage digital assets. ',
  },
  FRAX: {
    name: 'Frax',
    symbol: 'FRAX',
    address: '0xDA2585430fEf327aD8ee44Af8F1f989a2A91A3d2',
    chainId: 1313161554,
    decimals: 18,
    website: 'https://frax.finance/',
    description: 'Frax is the first fractional-algorithmic stablecoin protocol.',
    logoURI:
      'https://raw.githubusercontent.com/ava-labs/bridge-tokens/main/avalanche-tokens/0xBB69c92FBb4F1aFf528875056650c862F94D3CC1/logo.png',
  },
  MODA: {
    name: 'Moda Dao',
    symbol: 'MODA',
    address: '0x74974575D2f1668C63036D51ff48dbaa68E52408',
    chainId: 1313161554,
    decimals: 18,
    logoURI:
      'https://assets-global.website-files.com/60db372178574da6fa1958ea/60de2890676c09559b62e0e8_MODA%20Icon%20Logo%20(Gradient).png',
    website: 'https://www.modadao.io/',
    description: 'MODA is for anyone who creates or consumes content in the Web3 world.',
  },
  ROSE: {
    name: 'Rose',
    symbol: 'ROSE',
    address: '0xdcD6D4e2B3e1D1E1E6Fa8C21C8A323DcbecfF970',
    chainId: 1313161554,
    decimals: 18,
    logoURI: 'https://dex.nearpad.io/_next/image?url=%2Fimages%2Fcurrencies%2Frose.jpeg&w=96&q=75',
    website: 'https://app.rose.fi/#/',
    description:
      'Rose is a liquidity protocol on Aurora composed of a stablecoin & wrapped assets exchange, and a Collateralized Debt Position (CDP) based stablecoin utilizing interest bearing tokens as collateral.',
  },
  WANNA: {
    name: 'Wanna',
    symbol: 'WANNA',
    address: '0x7faA64Faf54750a2E3eE621166635fEAF406Ab22',
    chainId: 1313161554,
    decimals: 18,
    logoURI: 'https://wannaswap.finance/_next/image?url=%2Ficon.png&w=96&q=75',
    website: 'https://wannaswap.finance/en/home',
    decription: 'Built On Near. THE LIQUIDITY CENTRAL ON AURORA EVM',
  },
};
exports.tokens = _tokens;
