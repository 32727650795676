import { createTheme } from '@material-ui/core/styles';

const createThemeMode = isNightMode =>
  createTheme({
    palette: {
      type: isNightMode ? 'dark' : 'dark',
      background: {
        default: isNightMode ? '#27262c' : '#27262c', //  background
        // default: isNightMode ? '#242332' : '#fbf9f6', //  background
        paper: isNightMode ? '#DD517F' : '#DD517F', //  popout box
        // paper: isNightMode ? '#606077' : '#fff',
        primary: isNightMode ? '#712275' : '#712275', //  vault boxes
        // primary: isNightMode ? '#505067' : '#FBF6F0',
        // secondary: isNightMode ? '#3B3A4D' : '#F8F2EC',
        secondary: isNightMode ? '#362fbb' : '#362fbb', //  wallet overlay
        extra: isNightMode ? '#242332' : '#242332',
        dark: isNightMode ? '#2B2A3D' : '#2B2A3D',
        paused: isNightMode ? '#2B2A5A' : '#2B2A5A',
        retired: isNightMode ? '#d32f2f' : '#d32f2f',
        // retired: isNightMode ? '#d32f2f' : '#e57373',
        hover: isNightMode ? '#2B2A3D' : '#2B2A3D',
        border: isNightMode ? '#2B2A3D' : '#2B2A3D',
        overlay: isNightMode ? 'rgba(0, 0, 0, 0.75)' : 'rgba(0, 0, 0, 0.75)',
      },
      primary: {
        main: isNightMode ? '#fff' : '#fff',
      },
      secondary: {
        main: isNightMode ? '#fff' : '#fff',
      },
      text: {
        primary: isNightMode ? '#fff' : '#fff',
        secondary: isNightMode ? '#B0B0DD' : '#B0B0DD',
      },
    },
    overrides: {
      MuiButton: {
        label: {
          color: isNightMode ? '#fff' : '#fff',
        },
      },
      // for dropdown menu items
      MuiButtonBase: {
        root: {
          color: isNightMode ? '#fff' : '#fff',
        },
      },
      MuiCheckbox: {
        colorPrimary: {
          color: isNightMode ? '#fff' : '#fff',
        },
        colorSecondary: {
          color: isNightMode ? '#fff' : '#fff',
        },
      },
    },
  });

export default createThemeMode;
