'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
exports.cronos = void 0;
var beefyfinance_1 = require('./platforms/beefyfinance');
var vvs_1 = require('./platforms/vvs');
var crona_1 = require('./platforms/crona');
var tokens_1 = require('./tokens/tokens');
var convertSymbolTokenMapToAddressTokenMap_1 = require('../../util/convertSymbolTokenMapToAddressTokenMap');
var _cronos = {
  platforms: {
    beefyfinance: beefyfinance_1.beefyfinance,
    vvs: vvs_1.vvs,
    crona: crona_1.crona,
  },
  tokens: tokens_1.tokens,
  tokenAddressMap: convertSymbolTokenMapToAddressTokenMap_1.convertSymbolTokenMapToAddressTokenMap(
    tokens_1.tokens
  ),
};
exports.cronos = _cronos;
